<template>
  <div class="uploadImg">
    <van-popup v-model="show" round position="bottom" :style="{ height: '20%' }">
      <div class="content">
        <div class="camera" @click="openCamera()">
          <img class="upload-icon" src="../assets/screenshot/upload_camera.png">
          <div>相机</div>
        </div>
        <div class="file" @click="openFile()">
          <img class="upload-icon" src="../assets/screenshot/upload_file.png">
          <div>文件</div>
        </div>
      </div>
    </van-popup>

    <input v-show="false" ref="cameraRef" type="file" capture="camera" accept="image/*" @change="fileChange($event)">
    <input v-show="false" ref="fileRef" type="file" accept="image/*" @change="fileChange($event)">
  </div>
</template>

<script>
import { compressAccurately } from 'image-conversion'

export default {
  name: 'UploadImg',
  data() {
    return {
      show: false, // 不启用下方选择框
    }
  },
  watch: {
    show(newVal) {
      if (newVal === false) {
        this.cancel() // cancel方法在UploadImg.js文件实现
      }
    },
  },
  mounted() {
    this.openFile()
  },
  methods: {
    openCamera() {
      this.$refs.cameraRef.dispatchEvent(new MouseEvent('click'))
    },

    openFile() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
    },

    fileChange(e) {
      let file = e.target.files[0]
      if (!file) return
      if (file.type.indexOf('image') < 0) {
        this.$toast.fail('请选择图片文件')
        return
      }
      //图片压缩
      compressAccurately(file, {
        size: 1000, // 大于1000KB将会被压缩
        accuracy: 0.9, // 精度
        scale: 0.5, // 缩放
      })
        .then((res) => {
          // 图片大于1000KB会被压缩成blob对象   否则返回file对象
          let type = Object.prototype.toString.call(res)
          if (type === '[object Blob]') {
            return this.blobToBase64(res)
          }
          if (type === '[object File]') {
            return this.getBase64(res)
          }
        })
        .then((res) => {
          this.show = false
          this.ok(res) // ok方法在UploadImg.js文件实现
        })
    },

    // 图片blob对象转base64
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          resolve(e.target.result)
        }
        fileReader.readAsDataURL(blob)
        fileReader.onerror = () => {
          reject(new Error('blobToBase64 error'))
        }
      })
    },

    // 图片file对象转base64
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.uploadImg {
  .content {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    font-size: 3.7vw;
  }
  .upload-icon {
    width: 14vw;
  }
}
</style>
