import Vue from 'vue';
import UploadImg from './UploadImg.vue';

let theUploadImg = function (content) {
    let Constructor = Vue.extend(UploadImg);
    return new Promise((res, rej) => {
        let DOM = new Constructor({
            el: document.createElement('div')
        })
        document.body.appendChild(DOM.$el);

        DOM.ok = function (value) {
            res(value)
        }
        DOM.cancel = function (value) {
            document.body.removeChild(DOM.$el);
        }
    })
}
export default theUploadImg;
